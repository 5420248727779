<template>
  <div
    class="tab-pane fade active show"
    id="pills-pending"
    role="tabpanel"
    aria-labelledby="pills-pending-tab"
  >
    <table class="customtable w-100">
      <thead>
        <tr>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            customer Id
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            Loan Id
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 30%"
          >
            customer name
          </th>
          <!-- <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            last name
          </th> -->
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            amount received
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            payment date
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 10%"
          >
            cheque number
          </th>
        </tr>
      </thead>
      <tbody v-if="loading && pendingTransactions.length < 1">
        <tr>
          <td colspan="4">
            <div class="d-flex align-items-center justify-content-center p5">
              <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <span
                class="text--black text--600 text--medium d-inline-flex ml-4"
                >Please wait</span
              >
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="pendingTransactions?.length > 0">
        <tr v-for="(pending, i) in pendingTransactions" :key="i">
          <td>
            <span class="table__body__text table__text">{{
              pending.customerId
            }}</span>
          </td>

          <td>
            <span class="table__body__text table__text text--capital">{{
              pending.loanId
            }}</span>
          </td>

          <td>
            <span class="table__body__text table__text text--capital">{{
              pending.customerName ? pending.customerName : ""
            }}</span>
          </td>

          <!-- <td>
            <span class="table__body__text table__text text--capital">{{
              pending.lastName ? pending.lastName : ""
            }}</span>
          </td> -->

          <td>
            <span
              style="color: #076f18"
              class="table__body__text table__text text--capital"
            >
              {{ addCommas(pending.amountReceived) }}
            </span>
          </td>

          <td>
            <span class="table__body__text table__text text--capital">{{
              formatDate(pending.paymentDate)
            }}</span>
          </td>

          <td>
            <span class="table__body__text table__text">{{
              pending.chequeNumber
            }}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="12">
            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                p5
              "
            >
              <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

              <span
                class="d-block text--danger text--600 text--medium mt-3 mb-5"
              >
                No data available</span
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "PendingTransactions",
  props: ["loading", "pendingTransactions"],

  methods: {
    addCommas(amount) {
      if (amount) {
        let fixedAmount = amount.toFixed(2);
        return fixedAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } else {
        return "0.00";
      }
    },

    formatDate(data) {
      return moment(data).format("DD-MMM-YYYY");
    },
  },
};
</script>
